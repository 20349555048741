import { useState, useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import { graphql } from '@lib/api';
import { useDebounce } from '@lib/hooks';
import { buildAgentFinderQuery } from '@lib/fragments';

// ---------------------------------------------------------

import languageList from '@src/data/language-list.json';

// ---------------------------------------------------------

import Grid from '@layout/grid';
import Pagination from '@components/pagination';
import RealtorCard from '@components/realtor-card';
import Select from '@components/select';
import Checkbox from '@components/checkbox';

// ---------------------------------------------------------

import {
  container,
  search_bar,
  language_sort,
  search,
  card_outer,
  zero_state,
  license_filter,
  license_checkboxes
} from './styles.module.scss';

// ---------------------------------------------------------

const PAGESIZE = 14;

// ---------------------------------------------------------

const SearchByAgent = () => {
  const topRef = useRef(null);
  const router = useRouter();
  const [pageNum, setPageNum] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [languages, setLanguage] = useState('');
  const [licenseStates, setLicenseStates] = useState([]);
  const debouncedSearch = useDebounce(searchTerm, 300);
  const [totalCount, setTotalCount] = useState(0);
  const [agentData, setAgentData] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    let variables = {
      language: languages,
      searchName: searchTerm,
      skip: (pageNum || 0) * PAGESIZE,
      states: licenseStates,
      take: PAGESIZE
    };

    if (debouncedSearch || languages !== '' || licenseStates.length > 0) {
      const fetchData = async () => {
        let data;
        try {
          data = await graphql(
            buildAgentFinderQuery(variables),

            variables
          );
        } catch (err) {
          setError(true);
        }

        setTotalCount(data?.agentSearch?.totalCount ?? 0);
        setAgentData(data?.agentSearch?.items ?? []);
        setLoading(false);
      };

      fetchData();
    }
  }, [debouncedSearch, pageNum, languages, licenseStates]);

  useEffect(() => {
    if (router?.isReady) {
      const page = router?.query?.page;
      setPageNum(parseInt(page) - 1 || 0);
    }
  }, [router.isReady, router?.query?.page]);

  // ---------------------------------------------------------

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
    setPageNum(0);
    router.push({ pathname: router.pathname, query: { ...router.query, page: '1' } }, undefined, {
      shallow: true
    });
  };

  // ---------------------------------------------------------

  const languageOptions = [{ foreignLanguage: 'All Languages' }]
    .concat(languageList)
    .map((el) => ({ label: el.foreignLanguage }));

  const selectLanguage = (el) => {
    if (el === 'All Languages') {
      return setLanguage('') && setPageNum(0);
    }

    setPageNum(0);
    setLanguage(el);
  };

  const licenseStateOptions = [
    { value: 'isOhioLicensed', label: 'Ohio' },
    { value: 'isKentuckyLicensed', label: 'Kentucky' },
    { value: 'isIndianaLicensed', label: 'Indiana' }
  ];

  // ---------------------------------------------------------
  return (
    <div className={container} ref={topRef}>
      <div className={search_bar}>
        <div className={language_sort}>
          <Select options={languageOptions} onChange={selectLanguage} />
        </div>

        <div className={license_filter}>
          <Checkbox
            items={licenseStateOptions}
            selections={licenseStates}
            onChange={setLicenseStates}
            selectAllLabel={false}
            className={license_checkboxes}
            layout="third"
            label="Licensed in:"
          />
        </div>

        <div className={search}>
          <input
            tabIndex="0"
            value={searchTerm}
            onChange={handleInputChange}
            placeholder="Search by agent name"
          />
        </div>
      </div>
      {debouncedSearch || languages !== '' || licenseStates.length > 0 ? (
        <>
          {!error && !loading && (
            <Grid layout="2">
              {agentData &&
                agentData.map((agent) => {
                  return (
                    <div className={card_outer} key={agent.agentID}>
                      <RealtorCard
                        email={agent.email}
                        teamName={agent.teamName}
                        imageSrc={agent.photoUrl}
                        languages={agent.languages}
                        designations={agent.designations}
                        name={agent.name}
                        phone={agent.phone}
                        title={agent.professionalTitle}
                        website={agent.websiteUrl}
                        isOhioLicensed={agent.isOhioLicensed}
                        isKentuckyLicensed={agent.isKentuckyLicensed}
                        isIndianaLicensed={agent.isIndianaLicensed}
                        theme="horizontalLarge"
                      />
                    </div>
                  );
                })}
            </Grid>
          )}

          {totalCount === 0 && (
            <div className={zero_state}>
              <p>No Agents match your search criteria.</p>
            </div>
          )}

          <div>
            {totalCount > PAGESIZE && !error && !loading && (
              <Pagination
                items={Array.from(Array(totalCount || 0).keys())}
                per={PAGESIZE}
                scrollToPosition={topRef}
                currentPage={pageNum}
              />
            )}
          </div>
        </>
      ) : (
        <div className={zero_state}>
          <p>Make a selection or type above to see a list of matching agents.</p>
        </div>
      )}
    </div>
  );
};

SearchByAgent.propTypes = {};

SearchByAgent.defaultProps = {};

export default SearchByAgent;
